a {
  /* reset link all settings */
  text-decoration: none;
  color: inherit;

  /* reset link all settings */
  &:hover {
    text-decoration: none;
    color: inherit;
  }

  /* reset link all settings */
  &:visited {
    text-decoration: none;
    color: inherit;
  }

  /* reset link all settings */

  &:active {
    text-decoration: none;
    color: inherit;
  }

  /* reset link all settings */

  &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.text-ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.section-border {
  padding: 15px;
  border-radius: 20px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .section-border {
    padding: 10px;
  }
}

.primary-border {
  margin-top: 21px;
  padding: 26px 19px;
  border: 1px solid rgba(9, 44, 76, 0.1);
  border-radius: 20px;
}
.c-l {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.f-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.f-b-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-a-c {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.f-s-c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.f-e-c {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.f-s-b {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;
}

.f-s-f {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.f-s-s {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.f-s-e {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px;
}

.f-c-f {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.f-c-e {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
}

.action-icon-rounded {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.action-icon-rounded button {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
}

.action-icon-rounded button svg {
  width: 18px;
  height: 18px;
}
.MuiAvatarGroup-root {
  align-items: center;
}

.MuiAvatarGroup-root:first-child {
  font-size: 14px;
  border-radius: 19px;
  height: fit-content;
  width: fit-content;
  padding: 5px 10px;
}

.header-bg {
  background: url("./assets/images/headerBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.react-tel-input {
  margin-top: 8px;
}

.react-tel-input.light {
  background-color: #f6f8fb;
}

.react-tel-input.dark {
  background-color: #1c1c1c;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
}

.react-tel-input .form-control {
  padding-block: 23px;
  width: 100%;
  padding-left: 67px;
}

.react-tel-input.light .form-control {
  background: #f5f5f5;
}

.react-tel-input.dark .form-control {
  background: #212332;
}

.react-tel-input .flag-dropdown {
  padding: 10px;
}

.react-tel-input.error {
  border: 1px solid red !important;
  border-radius: 5px !important;
}
